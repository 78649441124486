import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    eventType: { type: String, default: "professional" },
    isRecurrent: { type: Boolean, default: false },
  };

  static targets = [
    "personalFields",
    "professionalFields",
    "recurrentFields",
    "personalRequired",
    "professionalRequired",
    "recurrentRequired",
    "personalButton",
    "professionalButton",
  ];

  connect() {
    this.handlePersonalFields();
    this.handleProfessionalFields();
    this.handleRecurrentFields();
  }

  setEventType(event) {
    this.eventTypeValue = event.params.type;

    this.handlePersonalFields();
    this.handleProfessionalFields();
  }

  toggleRecurrent() {
    console.log("VAI");
    this.isRecurrentValue = !this.isRecurrentValue;
    this.handleRecurrentFields();
  }

  handlePersonalFields() {
    const removeClass = this.eventTypeValue == "personal" ? "hidden" : "block";
    const addClass = this.eventTypeValue == "personal" ? "block" : "hidden";

    if (this.isProfessional() == false) {
      this.personalButtonTarget.classList.add("label-option-selected");
    } else {
      this.personalButtonTarget.classList.remove("label-option-selected");
    }

    this.personalFieldsTargets.forEach((field) => {
      field.classList.remove(removeClass);
      field.classList.add(addClass);
    });

    this.personalRequiredTargets.forEach((field) => {
      if (!this.isProfessional()) {
        field.setAttribute("required", true);
      } else {
        field.removeAttribute("required");
      }
    });
  }

  handleProfessionalFields() {
    const removeClass = this.eventTypeValue == "professional" ? "hidden" : "block";
    const addClass = this.eventTypeValue == "professional" ? "block" : "hidden";

    if (this.isProfessional()) {
      this.professionalButtonTarget.classList.add("label-option-selected");
    } else {
      this.professionalButtonTarget.classList.remove("label-option-selected");
    }

    this.professionalFieldsTargets.forEach((field) => {
      field.classList.remove(removeClass);
      field.classList.add(addClass);
    });

    this.professionalRequiredTargets.forEach((field) => {
      if (this.isProfessional()) {
        field.setAttribute("required", true);
      } else {
        field.removeAttribute("required");
      }
    });
  }

  handleRecurrentFields() {
    const removeClass = this.isRecurrent() ? "hidden" : "block";
    const addClass = this.isRecurrent() ? "block" : "hidden";

    this.recurrentFieldsTargets.forEach((field) => {
      field.classList.remove(removeClass);
      field.classList.add(addClass);
    });

    this.recurrentRequiredTargets.forEach((field) => {
      if (this.isRecurrent()) {
        field.setAttribute("required", true);
      } else {
        field.removeAttribute("required");
      }
    });
  }

  isProfessional() {
    return this.eventTypeValue == "professional";
  }

  isRecurrent() {
    return this.isRecurrentValue == true;
  }
}
